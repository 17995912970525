<template>
  <div class="flex flex-wrap wrapper">
    <div class="px-4 w-full pb-24 pt-40">
      <p class="text-black font-bold head-title leading-none mb-10">Bucket Configuration</p>
      <h3 class="font-sansDemi text-2xl text-black py-3 leading-none"> 
        Would you like to keep track of channels
      </h3>

      <div class="flex flex-wrap mt-20">
        <div
          v-for="(track, index) in bucketOptions"
          :key="`${track.id}${index}`"
          class="w-1/3 px-4"
        >
          <div 
            class="w-full border border-grey-darker-100 rounded text-center py-5 cursor-pointer"
            :class="{
              'purple-light-shadow': track.id == selectedTrack
            }"
            @click="setTrack(track.id)"
          >
            <span 
              class="base-font-gray-200 text-xl"
              :class="{
                'purple-text': track.id == selectedTrack
              }"
            >
              {{ track.title }} 
            </span>
          </div>
          <ul class="pl-5 mt-10">
            <li
              v-for="text in track.text"
              :key="text"
              class="text-base text-black list-disc"
            >
              {{ text }}
            </li>
          </ul>
        </div>
      </div>

      <BottomNav @nextStep="nextStep" class="mt-32"/>
    </div>
  </div>
</template>

<script>
  import { bucketConfigOptions, warningToastConfig } from '@/helpers/constants'
  import ToastMessages from '@/data/toast_messages.json'
  import BottomNav from '@/components/Bucket/BottomNav'

  export default {
    name: 'BucketAdminConfiguration',
    components: {
      BottomNav
    },
    data(){
      return{
        bucketOptions: bucketConfigOptions,
        selectedTrack: null
      }
    },
    methods: {
      setTrack(id){
        this.selectedTrack = id
      },
      nextStep(){
        if(this.selectedTrack != null){
          const track = this.bucketOptions.find(elem => elem.id == this.selectedTrack)
          this.$router.push(track.route)
        }else{
          this.$toasted.show(ToastMessages.buckets.bucketSelect, warningToastConfig)  
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>